<template>
  <div class="bg">
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">
        <!-- Login v1 -->

        <div class="text-center">
          <div class="multi-spinner-container">
            <div class="multi-spinner">
              <div class="multi-spinner">
                <div class="multi-spinner">
                  <div class="multi-spinner">
                    <div class="multi-spinner">
                      <div class="multi-spinner" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <small class="text-white">Loading...</small>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  // BImg,
  // BLink,
  // BFormGroup,
  // BFormInput,
  // BInputGroupAppend,
  // BInputGroup,
  // BFormCheckbox,
  BCardText,
  // BCard,
  // BCardTitle,
  // BForm,
  // BButton,
  VBTooltip,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    // BCard,
    // BImg,
    // BLink,
    // BFormGroup,
    // BFormInput,
    // BInputGroupAppend,
    // BInputGroup,
    // BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // BCardTitle,
    // BForm,
    // BButton,
    // ValidationProvider,
    // ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: 'demo@user',
      userEmail: '999001',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      LottoList: [
        { text: 'กรุณาเลือกหวย', value: null, SubList: null },
      ],
      // THG :ผลสลากกินแบ่งรัฐบาล , GSB : ออมสิน, BAAC : ธกส,
      // STH : หุ้นไทย, HN : หวยฮานอย,
      // HNRB : หวยฮานอย แดงดำ, VT : หวยเวียดนาม, ML : หวยมาเลย์ (Magnum4D)
      // LottoSubHead:String,
      // STH = STH1 : หวยหุ้นไทย (เที่ยง), STH2 : หวยหุ้นไทย (บ่าย]), STH3 : หวยหุ้นไทย (เย็น),
      // HN = HNST : หวยฮานอย, HNSP : ฮานอยพิเศษ, HNVIP : ฮานอย วีไอพี Hanoi Vip
      // HNRB = HNR : ฮานอย แดง, HNB : ฮานอย ดำ,
      // VT = VTST : เวียดนาม, VTHCM : โฮจิมินห์ Ho Chi Minh,  VTVIP : เวียดนาม VIP
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  async mounted() {
    await this.login()
  },
  methods: {
    login() {
      useJwt
        .login({
          email: this.userEmail,
          password: this.password,
        })
        .then(response => {
          const userData = response.data
          useJwt.setToken(response.data.token)
          useJwt.setRefreshToken(response.data.refreshToken)
          localStorage.setItem('userData', JSON.stringify(userData))
          localStorage.setItem('UserToken', userData.UserToken)
          // localStorage.setItem('LottoList', JSON.stringify(this.LottoList))
          this.$ability.update(userData.ability)

          // ? This is just for demo purpose as well.
          // ? Because we are showing eCommerce app's cart items count in navbar
          // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

          // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
          this.$router
            .push(getHomeRouteForLoggedInUser(userData.role))
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Welcome to Image Dashboard',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'You have successfully logged ',
                },
              })
            })
            .catch(error => {
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'ERROR Email or Password incorrect',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: error,
            },
          })
        })
    },
  },
}
</script>

<style scoped>
.bg {
  background: #212534;
}
.card {
    border-radius: 14px !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
    background-color: rgba(0, 0, 0, 0.774) !important;
  }
  label {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }
  .btn-gradient-primary {
  background: linear-gradient(87deg, #590094 0, #b56bff 100%);
  border: 1px solid #7367f0 !important;
  color: #fff;
}

.multi-spinner-container {
  width: 150px;
  height: 150px;
  position: relative;
  margin: 30px auto;
  overflow: hidden;
}

.multi-spinner {
  position: absolute;
  width: calc(100% - 9.9px);
  height: calc(100% - 9.9px);
  border: 5px solid transparent;
  border-top-color: #64c6ff;
  border-radius: 50%;
  -webkit-animation: spin 5s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
  animation: spin 5s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
         transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
